export default {
  irs: [
    {
      fileName: 'IR-1.m4a',
      name: 'IR1',
      description: '',
      type: '',
    },
  ],
};
